.main_clients{
  width:100%;
}

.client_logo{
  width:60%;
  border:1px solid black;
}

.client_logo_cont{
  display:flex;
  align-items: center;
}

.corousel-icb{
  background-color: black;
  margin-left: -50px;
}

.corousel-icf{
  background-color: black;
  margin-left: 50px;
}

.client-lg{
  display:block;
}
.client-md{
  display:none;
}
.client-sm{
  display:none;
}


@media only screen and (min-width: 450px) and (max-width: 1050px) {
.client-lg{
  display:none;
}
.client-md{
  display:block;
}
.client-sm{
  display:none;
}
}




@media only screen and (max-width: 550px) {
.client-lg{
  display:none;
}
.client-md{
  display:none;
}
.client-sm{
  display:block;
}
.client_logo{
  width:80%;
  border:1px solid black;
}

.corousel-icb{
  background-color: black;
  margin-left: -20px;
}

.corousel-icf{
  background-color: black;
  margin-left: 20px;
}
.client_logo{
  width:60%;
  border:1px solid black;
}
}