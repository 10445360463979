@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css);
/* googleapis fonts */

/* Bootstrap icons */
body{
  background-color: aliceblue;
}

.main_head{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:600;
  letter-spacing: 2px;
  font-size:40px;
}

.main_head1{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:600;
  letter-spacing: 2px;
  font-size:40px;
  padding:0 10%;
}

.main_body{
  color:#202020;
  font-family:Karla;
  font-weight:300;
  letter-spacing: 1px;
  font-size:20px;
  text-align: justify;
}

.main_body_sm{
  font-family:Karla;
  font-weight:400;
  font-size:15px;
}

.main_subhead{
  color:#202020;
  font-family:Roboto;
  font-weight:500;
  letter-spacing: 1.5px;
  font-size:20px;
}

.navbar a:hover {
  cursor:pointer;
  color: #ad1919;
}

div:hover{
  cursor:context-menu;
}







@media only screen and (min-width: 450px) and (max-width: 1050px) {
  .main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
  
  .main_head1{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
    padding:0 10%;
  }
  
  .main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:18px;
  }
  
  .main_body_sm{
    font-family:Karla;
    font-weight:400;
    font-size:12px;
  }
  
  .main_subhead{
    color:#202020;
    font-family:Roboto;
    font-weight:500;
    letter-spacing: 1.5px;
    font-size:18px;
  }
}
















@media only screen and (max-width: 450px) {
  .main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:25px;
  }
  
  .main_head1{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:25px;
    padding:0 10%;
  }
  
  .main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:15px;
  }
  
  .main_body_sm{
    font-family:Karla;
    font-weight:400;
    font-size:12px;
  }
  
  .main_subhead{
    color:#202020;
    font-family:Roboto;
    font-weight:500;
    letter-spacing: 1.5px;
    font-size:15px;
  }
}

.main_about{
  height:100%;
  width:100%;
  padding:50px 10%;
}

@media only screen and (max-width: 450px) {
  .main_about{
    padding:50px 5%;
  }
}


.main_banner{
  background-color: #1488CC;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2B32B2, #1488CC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height:100vh;
  width:100%;
  margin:0;
  color:aliceblue;
  font-family:Karla;
  cursor: context-menu;
}
.main-line{
  display:flex;
  flex-direction: column;
  justify-content:flex-start;
  text-align: center;
  margin-top:7%;
}
.main-head{
  font-size:55px;
  font-weight:600;
  letter-spacing: 5px;
}
.main-head-2{
  font-size:35px;
  font-weight:300;
  letter-spacing: 2px;
  margin-top: 5%;
}

.scroll:hover{
  cursor: pointer;
}

/****************************************4k laptop*****************************************************/
@media only screen and (min-width: 2300px) {
  .main-head{
    font-size:110px;
    font-weight:600;
    letter-spacing: 5px;
    margin-left:10%;
  }
  .main-head-2{
    font-size:70px;
    font-weight:300;
    letter-spacing: 2px;
    margin-left:10%;
  }
  
  .scroll{
    font-size: 40px;
  }
}



@media only screen and (min-width: 2000px)and (max-width: 2300px) {
  .main-head{
    font-size:100px;
    margin-left:10%;
  }
  .main-head-2{
    font-size:65px;
    margin-left:10%;
  }
  
  .scroll{
    font-size: 30px;
  }
}


@media only screen and (min-width: 1750px)and (max-width: 2000px) {
  .main-head{
    font-size:90px;
    margin-left:10%;
  }
  .main-head-2{
    font-size:55px;
    margin-left:10%;
  }
  
  .scroll{
    font-size: 25px;
  }
}

@media only screen and (min-width: 1600px)and (max-width: 1750px) {
  .main-head{
    font-size:80px;
    margin-left:10%;
  }
  .main-head-2{
    font-size:50px;
    margin-left:10%;
  }
  
  .scroll{
    font-size: 25px;
  }
}

@media only screen and (min-width: 1450px)and (max-width: 1600px) {
  .main-head{
    font-size:60px;
    margin-left:10%;
  }
  .main-head-2{
    font-size:30px;
    margin-left:10%;
  }
  
  .scroll{
    font-size: 20px;
  }
}


/****************************************laptops*****************************************************/

@media only screen and (min-width: 1000px)and (max-width: 1200px) {
  
  .main-head{
    font-size:45px;
    margin-left:10%;
  }
  .main-head-2{
    font-size:30px;
    margin-left:10%;
    padding-top: 0px;
  }
  
  .scroll{
    font-size: 15px;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1100px) and (orientation:portrait) {
  .main_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-head{
    font-size:60px;
    padding-bottom: 50px;
    margin-left:15%;
  }
  .main-head-2{
    font-size:30px;
    padding-bottom: 20px;
    width:100%;
  }
  
  .scroll{
    font-size: 20px;
    margin-top: 50px;
  }
  .main-line{
    width:100%;
    text-align:center;
    align-items: center;
  }
  .animation{
    width:80%;
  }
}

@media only screen and (min-width: 770px)and (max-width: 1000px) {
  .main-head{
    font-size:40px;
  }
  .main-head-2{
    font-size:25px;
  }
  
  .scroll{
    font-size: 15px;
  }
  .main-line{
    width:100%;
    text-align:center;
    align-items: center;
  }
}
@media only screen and (min-width: 700px)and (max-width: 770px) {
  
  .main-head{
    font-size:35px;
    padding-bottom: 50px;
  }
  .main-head-2{
    font-size:20px;
    padding-bottom: 20px;
  }
  
  .scroll{
    font-size: 15px;
  }
  .main-line{
    width:100%;
    text-align:center;
    align-items: center;
  }
  .animation{
    width:80%;
    margin-left:10%;
  }
  
} 

@media only screen and (min-width: 350px)and (max-width: 700px) {
  
  .main-head{
    font-size:35px;
    padding-bottom: 50px;
  }
  .main-head-2{
    font-size:20px;
    padding-bottom: 20px;
  }
  
  .scroll{
    font-size: 15px;
  }
  .main-line{
    width:100%;
    text-align:center;
  }
  .animation{
    width:80%;
    margin-left:10%;
  }
  
} 
@media only screen and (min-width: 50px)and (max-width: 600px) and (orientation:landscape){
  
  .main-head{
    font-size:25px;
    padding-bottom: 30px;
  }
  .main-head-2{
    font-size:16px;
    padding-bottom: 15px;
  }
  
  .scroll{
    font-size: 12px;
  }
  .main-line{
    width:50%;
    text-align:center;
  }
  .animation{
    width:40%;
    margin-left:10%;
  }
} 


@media only screen and (min-width: 600px)and (max-width: 900px) and (orientation:landscape){
  
  .main-head{
    font-size:30px;
    padding-bottom: 35px;
  }
  .main-head-2{
    font-size:15px;
    padding-bottom: 15px;
  }
  
  .scroll{
    font-size: 10px;
  }
  .main-line{
    width:50%;
    text-align:center;
  }
  .animation{
    width:40%;
    margin-left:10%;
  }
} 


@media only screen and (min-width: 50px)and (max-width: 350px) {
  
  .main-head{
    font-size:30px;
    padding-bottom: 50px;
    letter-spacing: 3px;
  }
  .main-head-2{
    font-size:16px;
    padding-bottom: 20px;
  }
  
  .scroll{
    font-size: 10px;
  }
  .main-line{
    width:100%;
    text-align:center;
  }
  .animation{
    width:80%;
    margin-left:10%;
  }
} 
.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .cardContainer {
    background-color: blue;
    /*margin: 20px;*/
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: center;
    box-shadow: 5px 4px 5px rgba(0, 0, 0, 0.4);
  }
  .titleContainer {
    background-color: white;
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 5px;
  }
  #title {
    flex-grow: 3;
    position: relative;
  }
  
  #rating {
    width: 40%;
    background-color: orange;
    flex-direction: row;
  }
  .cardImgContainer {
    background-color: green;
  }
  
  .cardImgContainer img {
    object-fit: fill;
  }
  
  .cardTextContainer {
    background-color: red;
  }
  
@media only screen and (min-width: 1500px) {
  .laptop{
    display:none;
  }
  .big{
    display:block;
  }
  .small-laptop{
    display:none;
  }
  .tabs{
    display:none;
  }
}

@media only screen and (min-width: 1200px)and (max-width: 1500px) {
  .laptop{
    display:block;
  }
  .big{
    display:none;
  }
  .small-laptop{
    display:none;
  }
  .tabs{
    display:none;
  }
}

@media only screen and (min-width: 1000px)and (max-width: 1200px) {
  .laptop{
    display:none;
  }
  .big{
    display:none;
  }
  .small-laptop{
    display:block;
  }
  .tabs{
    display:none;
  }
}

@media only screen and (max-width: 1000px) {
  .laptop{
    display:none;
  }
  .big{
    display:none;
  }
  .small-laptop{
    display:none;
  }
  .tabs{
    display:block;
  }
  .main-prod{
  }
}
.main_services{
  padding:50px 10% 0 10%;
}

.service-card{
  border-radius: 10px;
  margin-top:30px;
}

.service-img{
  width: 100%;
  border-radius: 10px;
  border:1px solid black;
  box-shadow: 10px 5px 20px black;
}

.service-desc{
  top:0;
  opacity: 0;
  position:absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width:93%;
  height:100%;
  border-radius: 10px;
  transition: opacity 0.2s linear;
  color:white;
  display:flex;
  align-items: center;
  cursor:pointer;
}

.service-desc:hover{
  opacity: 1;
}
.service-imgcont{
  width:90%;
  position: relative;
}


@media only screen and (max-width: 1000px) {
  .main_services{
    padding:100px 10% 0 10%;
  }  

  .service-imgcont{
    width:100%;
    position: relative;
  }
}
.main_clients{
  width:100%;
}

.client_logo{
  width:60%;
  border:1px solid black;
}

.client_logo_cont{
  display:flex;
  align-items: center;
}

.corousel-icb{
  background-color: black;
  margin-left: -50px;
}

.corousel-icf{
  background-color: black;
  margin-left: 50px;
}

.client-lg{
  display:block;
}
.client-md{
  display:none;
}
.client-sm{
  display:none;
}


@media only screen and (min-width: 450px) and (max-width: 1050px) {
.client-lg{
  display:none;
}
.client-md{
  display:block;
}
.client-sm{
  display:none;
}
}




@media only screen and (max-width: 550px) {
.client-lg{
  display:none;
}
.client-md{
  display:none;
}
.client-sm{
  display:block;
}
.client_logo{
  width:80%;
  border:1px solid black;
}

.corousel-icb{
  background-color: black;
  margin-left: -20px;
}

.corousel-icf{
  background-color: black;
  margin-left: 20px;
}
.client_logo{
  width:60%;
  border:1px solid black;
}
}
.navbar {
  background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:rgb(0, 0, 0);
  position: fixed;
  height:80px;
  top: -80px;
  width: 100vw;
  display: block;
  transition: top 0.3s;
  z-index:100;
  display:flex;
  box-shadow: 0 0 20px rgb(15, 15, 15);
}


.sidenav{
  display:none;
}

.dropbtn:hover {
  cursor: pointer;
}


.dropdown-content a:hover {
  background-color: #dbdada;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 100vh;
  line-height: 14px;
  white-space: nowrap;
}

.dropdown-content a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {display: block;}


.navlinks{
  display: flex;
  width:60%;
}
.navbar a {
  display: block;
  color: #000000;
  text-align: center;
  padding: 15px;
  padding-right:10%;
  text-decoration: none;
  font-size: 17px;
  font-family:Roboto;
  font-weight:400;
  letter-spacing: 1.5px;
}
  
.navbar a:hover {
  cursor:pointer;
  color: #ad1919;
}

.nav-img{
  padding-left:7%;
  float:left;
  height:65px;
}
.nav-img1{
  margin-left:7%;
  float:left;
  height:65px;
}
.nav-img1:hover{
  cursor: pointer;
}

.navbar1 {
  background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:rgb(0, 0, 0);
  position: fixed;
  height:80px;
  top: 0px;
  width: 100vw;
  border-bottom: solid 1px black;
  display: flex;
  align-items: center;
  z-index:10;
  box-shadow: 0 0 20px rgb(15, 15, 15);
}

.navbar1 a {
  display: block;
  color: #000000;
  text-align: center;
  padding:15px 15px;
  padding-right:10%;
  text-decoration: none;
  font-size: 17px;
}

.navbar1 a:hover {
  cursor:pointer;
  color: #ad1919;
}


@media only screen and (min-width: 800px)and (max-width: 1100px) {
  .navbar {
    background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:rgb(0, 0, 0);
    position: fixed;
    height:60px;
    top: -60px;
    width: 100vw;
    display: block;
    transition: top 0.3s;
    z-index:100;
    display:flex;
    box-shadow: 0 0 20px rgb(15, 15, 15);
  }
  .navbar1 {
    background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:rgb(0, 0, 0);
    position: fixed;
    height:60px;
    top: 0px;
    width: 100vw;
    display: block;
    z-index:100;
    display:flex;
    box-shadow: 0 0 20px rgb(15, 15, 15);
  }
  .navlinks{
    display: flex;
    width:60%;
  }
  .nav-img{
  padding-left:20px;
  float:left;
  height:50px;
}
.nav-img1{
  padding-left:20px;
  float:left;
  height:50px;
}
.navbar a {
  padding: 15px;
  padding-right:5%;
  text-decoration: none;
  font-size: 14px;
  font-family:Roboto;
  font-weight:400;
  letter-spacing: 1.5px;
}
.navbar1 a {
  padding: 15px;
  padding-right:5%;
  text-decoration: none;
  font-size: 14px;
  font-family:Roboto;
  font-weight:400;
  letter-spacing: 1.5px;
}
.sidenav{
  display:none;
}
}


@media only screen and (min-width: 700px)and (max-width: 800px) {
  .navbar {
    background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:rgb(0, 0, 0);
    position: fixed;
    height:60px;
    top: -60px;
    width: 100vw;
    display: block;
    transition: top 0.3s;
    z-index:100;
    display:flex;
    box-shadow: 0 0 20px rgb(15, 15, 15);
  }
  .navbar1 {
    background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:rgb(0, 0, 0);
    position: fixed;
    height:60px;
    top: 0px;
    width: 100vw;
    display: block;
    transition: top 0.3s;
    z-index:100;
    display:flex;
    box-shadow: 0 0 20px rgb(15, 15, 15);
  }
  .navlinks{
    display: flex;
    width:70%;
  }
  .nav-img{
    padding-left:20px;
    float:left;
    height:50px;
  }
  .nav-img1{
    padding-left:20px;
    float:left;
    height:50px;
  }
  .navbar a {
    padding: 15px;
    padding-right:5%;
    text-decoration: none;
    font-size: 14px;
    font-family:Roboto;
    font-weight:400;
    letter-spacing: 1.5px;
  }
  .navbar1 a {
    padding: 15px;
    padding-right:5%;
    text-decoration: none;
    font-size: 14px;
    font-family:Roboto;
    font-weight:400;
    letter-spacing: 1.5px;
  }
.sidenav{
  display:none;
}
}
@media only screen and (min-width: 10px)and (max-width: 700px) {
  .navbar1{
    display:flex;
    height:60px;
  }
  .nav-img1{
    padding-left:20px;
    float:left;
    height:50px;
  }
  .navbar{
    display:none;
  }
  .navlinks{
    display:none;
  }
  .sidenav{
    display:block;
		position: absolute;
    z-index:100;
  }

	.sidenav-button {
		position: absolute;
		top: 50%;
		left: 0;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		height: 100px;
		width: 80px;
		border-radius: 0 80px 80px 0;
		background-color:rgba(0, 14, 92, 0.8);
    position:fixed;
    color:azure;
	}

  .sidenav-open {
		position: absolute;
		top: 50%;
		left: 70%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
    font-size: 35px;
	}

  .sidenav-close {
    font-size: 35px;
    color:rgb(48, 46, 46);
    right:0;
    position: absolute;
    float:right;
    padding: 30px;
	}
  
  .sidenav-menu{
    height:100vh;
    width:0;
    display: flex;
    flex-direction: column;
    background-color: #d0e8f5;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d0e8f5, #99dafd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 10;
    position:fixed;
    overflow-x: hidden;
    transition: width 0.5s;
    text-align:center;
  }

  .sidenav-links {
    display:flex;
    flex-direction:column;
  }

  .sidenav a {
    color: #000000;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    font-family:Roboto;
    font-weight:400;
    letter-spacing: 1.5px;
    margin-top: 10%;
  }
    
  .sidenav a:hover {
    cursor:pointer;
    color: #ad1919;
  }
  
  .side-dropdown a{
    font-size: 18px;
    margin: 0;
    padding:5%;
    border-bottom: 0.1px solid rgba(0,0,0,0.2);
  }

  .side-dropdown{
    display:none;
    flex-direction: column;
    background-color: rgba(0,0,0,0.1);
    max-width:40%;
    margin-left:30%;
    margin-top:10px;
    border-radius: 4px;
  }
}


@media only screen and (min-width: 10px)and (max-width: 900px) and (orientation:landscape){
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-height: 60vh;
    overflow-y:scroll;
  }
}
.about_banner{
  align-items: center;
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  width:100%;
  margin:80px 0 0 0;
}

.about_main_head{
  color:aliceblue;
  font-family:Karla;
  font-weight:600;
  letter-spacing: 2px;
  font-size:100px;
  text-align: left;
  text-align: center;
}

.about_foot_images{
  align-items: center;
}

@media only screen and (min-width: 1100px)and (max-width: 1250px) {
  .about_main_head{
    color:aliceblue;
    font-family:Karla;
    font-weight:600;
    letter-spacing: 2px;
    font-size:70px;
    text-align: left;
    text-align: center;
  }
}

@media only screen and (min-width: 700px)and (max-width: 1100px) {

  .about_banner{
    align-items: center;
    margin:60px 0 0 0;
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    width:100%;
  }
  
  .about_main_head{
    color:aliceblue;
    font-family:Karla;
    font-weight:600;
    letter-spacing: 2px;
    font-size:70px;
    text-align: center;
  }
}
@media only screen and (min-width: 500px)and (max-width: 700px) {

  
  .about_banner{
    align-items: center;
    margin:60px 0 0 0;
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    width:100%;
  }
  .about_main_head{
    color:aliceblue;
    font-family:Karla;
    font-weight:600;
    letter-spacing: 2px;
    font-size:50px;
    text-align: center;
  }
}

@media only screen and (min-width: 50px)and (max-width: 500px) {

  .about_banner{
    align-items: center;
    margin:60px 0 0 0;
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    width:100%;
  }
  .about_main_head{
    color:aliceblue;
    font-family:Karla;
    font-weight:600;
    letter-spacing: 2px;
    font-size:35px;
    text-align: center;
  }
}
.footer{
  background-color: #020222; /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0a0b13, #07123f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:white;
  width:100%;
  text-align:center;
  padding:20px;
}
.footer1{
  padding:3% 3%;
  text-align:left;
}
.footer_body{
  color:#a19f9f;
  font-family:Karla;
  font-weight:400;
  font-size:18px;
}
.footer_head{
  color:aliceblue;
  font-family:Roboto;
  font-weight:500;
  letter-spacing: 1.5px;
  font-size:22px;
  padding:15px;
}
.footer li{
  list-style-type: circle;
  padding:5px 0;
}


a{
  text-decoration: none;
  color:#a19f9f;
}

a:hover{
  cursor: pointer;
  color: #ad1919;
}


@media only screen and (min-width: 10px)and (max-width: 1000px){
  .footer_body{
    color:#a19f9f;
    font-family:Karla;
    font-weight:400;
    font-size:15px;
  }
  .footer_head{
    color:aliceblue;
    font-family:Roboto;
    font-weight:500;
    letter-spacing: 1.5px;
    font-size:20px;
    padding:15px;
  }
  .footer1{
    padding:0% 8%;
    text-align:left;
  }
  .copyright{
    font-size: 12px;
  }
}
.products{
  position:relative;
  text-align: left;
  background-color: aliceblue;
}

.banner_img{
  margin-top:80px;
  width:100%;
}
.banner_img2{
  display:none;
}
.prod_body{
  padding:0px 10%;
  text-align: justify;
}

.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:15px;
}
.prod_main_head{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:600;
  letter-spacing: 2px;
  font-size:40px;
  text-align: left;
}
.prod_main_head2{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:500;
  letter-spacing: 2px;
  font-size:25px;
  text-align: left;
}

.prod_main_body{
  color:#202020;
  font-family:Karla;
  font-weight:300;
  letter-spacing: 1px;
  font-size:20px;
}

.prod_image{
  display:flex;
  align-items: center;
  background-size: auto;
}


.products ul li::before {
  content: "\2022";
  color: rgba(0, 47, 255, 0.562);
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.prod_button{
  margin-right:30px;
}

.prod_slide{
  z-index: 0;
  color: aliceblue;
  width:80%;
  margin-left:10%;
  text-align:center;

  --slider-height-percentage: 58%;
--slider-transition-duration: 900ms;
--organic-arrow-thickness: 4px;
--organic-arrow-border-radius: 0px;
--organic-arrow-height: 40px;
--organic-arrow-color: #4e4e4e;
--control-button-width: 15%;
--control-button-height: 25%;
--control-button-background: none;
--control-bullet-color: #aebeff;
--control-bullet-active-color: #4e6bdf;
--loader-bar-color: #851515;
--loader-bar-height: 5px;
}
.prod_slide_img{
  width:100%;
}



@media only screen and (min-width: 800px)and (max-width: 1100px) {
  .banner_img{
    margin-top:60px;
  }
  .banner_img2{
    display:none;
  }
  .prod_body{
    padding:0px 10%;
  }
  
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:12px;
}
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
  .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:20px;
  }
}

@media only screen and (min-width: 700px)and (max-width: 800px) {
  .banner_img{
    margin-top:60px;
  }
  .banner_img2{
    display:none;
  }
  .prod_body{
    padding:0px 10%;
  }
  
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:25px;
    }
      .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:16px;
    }
  .prod_main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:15px;
  }
  
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:12px;
}
}

@media only screen and (min-width: 700px) and (max-width: 800px)  and (orientation:portrait) {
  .banner_img{
    display:none;
  }
  .banner_img2{
    display:block;
    margin-top:60px;
    width:100%;
  }
  .prod_body{
    padding:0px 10%;
  }
  
  .prod_main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:20px;
  }
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
  .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:22px;
  }
  .prod_slide{
    z-index: 0;
    color: aliceblue;
    width:100%;
    margin-left:0%;
  }
  
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:15px;
}
}

@media only screen and (min-width: 10px)and (max-width: 700px) {
  .banner_img{
    display:none;
  }
  .banner_img2{
    display:block;
    margin-top:0px;
    padding-top: 60px;
    width:100%;
  }
  .prod_main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:17px;
  }
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:25px;
  }
  
  .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:18px;
  }
  .prod_slide{
    z-index: 0;
    color: aliceblue;
    width:100%;
    margin-left:0%;
    text-align:center;
  
    --slider-height-percentage: 48%;
  --slider-transition-duration: 900ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #4e4e4e;
  --control-button-width: 15%;
  --control-button-height: 25%;
  --control-button-background: none;
  --control-bullet-color: #aebeff;
  --control-bullet-active-color: #4e6bdf;
  --loader-bar-color: #851515;
  --loader-bar-height: 5px;
  }
  
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:10px;
}
}
.services h1{
    margin-top:20px;
}
.contact{
  padding:0 10%;
  margin-top:120px;
}
.map{
  width:100%;
  height:500px;
  padding:10px;
  border:3px solid rgb(147, 146, 158);
  box-shadow: 0 0 10px rgb(15, 15, 15);
  border-radius: 10px;
}
.contact-cards{
  width:100%;
}
.contact-box{
  background-color: #dbeaf8;
  padding:0 5% 5% 5%;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top:100px;
  margin:100px 50px 0 50px;
  border-radius: 10px;
}

.contact-circle{
  background-color: #dbeaf8;
  border:3px solid #020d36;
  height:70px;
  width:70px;
  border-radius: 50%;
  margin-top:-35px;
  position:absolute;
  display:flex;
  align-items: center;
  transition: height 0.3s, width 0.5s, margin-top 0.3s;
}
.contact-circle-logo{
  margin-left: auto;
  margin-right: auto;
  font-size:25px;
  color:#020d36;
  transition: font-size 0.3s;
}

.contact-box:hover .contact-circle-logo {
  color:#dbeaf8;
  font-size:30px;
}

.contact-box:hover .contact-circle {
  background-color:#020d36;
  height:80px;
  width:80px;
  margin-top:-40px;
}

.contact-headtext{
  padding-top:15%;
  color:#020a2b;
  font-family:Poppins;
  font-weight:600;
  letter-spacing: 2px;
  font-size:25px;
}

.contact-bodytext{
  color:#001153;
}

.contact table{
  width:100%;
}

.td1{
  text-align: right;
  padding:2px;
}

.td2{
  text-align: left;
  padding:2px 5px;
}

.contact a{
  color:#001153;
}

.contact a:hover{
  color:#324894;
}

.form{
  margin:100px 0;
  box-shadow: 0 0 100px rgb(15, 15, 15);
}

.form-field-section{
  background-color: #213979;
  padding:3%;
}

.form-head{
  font-family:Poppins;
  font-weight:600;
  color:white;
  letter-spacing: 2px;
  font-size:45px;
}

.contact-form{
  margin:0 50px;
}
.contact-form p{
  color:white;
}
.main_head_contact{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:600;
  letter-spacing: 2px;
  font-size:40px;
}

@media only screen and (min-width: 1100px)and (max-width: 1350px) {
  .contact-headtext{
    padding-top:15%;
    color:#020a2b;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:20px;
  }
  
  .contact-bodytext{
    color:#001153;
    font-size:15px;
  }

  .contact{
    padding:0 5%;
    margin-top:120px;
  }
}


@media only screen and (min-width: 990px)and (max-width: 1100px) {
  .contact-headtext{
    padding-top:15%;
    color:#020a2b;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:20px;
  }
  
  .contact-bodytext{
    color:#001153;
    font-size:15px;
  }

  .contact{
    padding:0 2%;
    margin-top:100px;
  }

  .main_head_contact{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
}


@media only screen and (min-width: 750px)and (max-width: 990px) {
  .contact-headtext{
    padding-top:15%;
    color:#020a2b;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:20px;
  }
  
  .contact-bodytext{
    color:#001153;
    font-size:15px;
  }

  .contact{
    padding:0 10%;
    margin-top:100px;
  }
  .contact-box{
    background-color: #dbeaf8;
    padding:0 15% 5% 15%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:100px;
    margin:100px 50px 0 50px;
    border-radius: 10px;
  }

  .td1{
    text-align: right;
    padding:2px;
    width:50%;
  }
  
  .td2{
    text-align: left;
    padding:2px 5px;
    width:50%;
  }

  .form-animation{
    display: none;
  }
  .form-head{
    font-family:Poppins;
    font-weight:600;
    color:white;
    letter-spacing: 2px;
    font-size:35px;
  }
  
  .main_head_contact{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
}



@media only screen and (min-width: 700px)and (max-width: 750px) {
  .contact-headtext{
    padding-top:15%;
    color:#020a2b;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:20px;
  }
  
  .contact-bodytext{
    color:#001153;
    font-size:15px;
  }

  .contact{
    padding:0 5%;
    margin-top:100px;
  }
  .contact-box{
    background-color: #dbeaf8;
    padding:0 5% 5% 5%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:100px;
    margin:100px 50px 0 50px;
    border-radius: 10px;
  }

  .td1{
    text-align: right;
    padding:2px;
    width:50%;
  }
  
  .td2{
    text-align: left;
    padding:2px 5px;
    width:50%;
  }

  .form-animation{
    display: none;
  }
  .form-head{
    font-family:Poppins;
    font-weight:600;
    color:white;
    letter-spacing: 2px;
    font-size:35px;
  }
  
  .main_head_contact{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
}



@media only screen and (min-width: 500px)and (max-width: 700px) {
  .contact-headtext{
    padding-top:15%;
    color:#020a2b;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:20px;
  }
  
  .contact-bodytext{
    color:#001153;
    font-size:15px;
  }

  .contact{
    padding:0 0%;
    margin-top:0px;
  }
  .contact-box{
    background-color: #dbeaf8;
    padding:0 5% 5% 5%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:100px;
    margin:100px 50px 0 50px;
    border-radius: 10px;
  }

  .td1{
    text-align: right;
    padding:2px;
    width:50%;
  }
  
  .td2{
    text-align: left;
    padding:2px 5px;
    width:50%;
  }

  .form-animation{
    display: none;
  }
  .form-head{
    font-family:Poppins;
    font-weight:600;
    color:white;
    letter-spacing: 2px;
    font-size:35px;
  }
  
  .main_head_contact{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    padding-top:80px;
    font-size:30px;
  }
}



@media only screen and (min-width: 50px)and (max-width: 500px) {
  .contact-headtext{
    padding-top:10%;
    color:#020a2b;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:15px;
  }
  
  .contact-bodytext{
    color:#001153;
    font-size:10px;
  }

  .contact{
    padding:0 0%;
    margin-top:0px;
  }
  .contact-box{
    background-color: #dbeaf8;
    padding:0 5% 5% 5%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:100px;
    margin:50px 0px 0 20px;
    border-radius: 10px;
  }

  .td1{
    text-align: right;
    padding:2px;
    width:50%;
  }
  
  .td2{
    text-align: left;
    padding:2px 5px;
    width:50%;
  }

  .form-animation{
    display: none;
  }

  .contact-circle{
    background-color: #dbeaf8;
    border:1px solid #020d36;
    height:40px;
    width:40px;
    border-radius: 50%;
    margin-top:-20px;
    position:absolute;
    display:flex;
    align-items: center;
    transition: height 0.3s, width 0.5s, margin-top 0.3s;
  }
  .contact-circle-logo{
    margin-left: auto;
    margin-right: auto;
    font-size:15px;
    color:#020d36;
    transition: font-size 0.3s;
  }

  .contact-box:hover .contact-circle-logo {
    color:#dbeaf8;
    font-size:20px;
  }
  
  .contact-box:hover .contact-circle {
    background-color:#020d36;
    height:45px;
    width:45px;
    margin-top:-22px;
  }

  .form-head{
    font-family:Poppins;
    font-weight:600;
    color:white;
    letter-spacing: 2px;
    font-size:30px;
  }

  .form{
    margin:70px 0 20px 0;
    box-shadow: 0 0 60px rgb(15, 15, 15);
  }  
  .main_head_contact{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    padding-top:80px;
    font-size:30px;
  }
}
.error{
    margin: 0;
    padding: 0;
  }
  
  .error a {
    text-decoration: none;
  }
  
 .error{
    font-weight: 600;
    color: #343434;
    width:100%;
    overflow-x: hidden;
  }
  
  .error_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width:100%;
    padding:50px;
    background-image: linear-gradient(-225deg, #1A1A1A, #343434);
  }
  .error_section_subtitle {
    color: #25F193;
    text-transform: uppercase;
    letter-spacing: 5pt;
    font-weight: 500;
    font-size: 0.8rem;
    margin-bottom: -5em;
  }
  .error_section .error_title {
    --x-shadow: 0;
    --y-shadow: 0;
    --x:50%;
    --y:50%;
    font-size: 15rem;
    transition: all 0.2s ease;
    position: relative;
    padding: 2rem;
  }
  .error_section .error_title:hover {
    transition: all 0.2s ease;
    text-shadow: var(--x-shadow) var(--y-shadow) 10px #1A1A1A;
  }
  .error_section .error_title p {
    position: absolute;
    top: 2rem;
    left: 2rem;
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.05), transparent);
    background-position: var(--x) var(--y);
    background-repeat: no-repeat;
    text-shadow: none;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.1s ease;
  }
  
  .error .btn {
    padding: 0.8em 1.5em;
    border-radius: 99999px;
    background-image: linear-gradient(to top, #03A9F4, #00BCD4);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2), inset 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    text-shadow: 0px 1px #343434;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.5pt;
    font-size: 0.8rem;
    font-weight: 700;
    transition: ease-out 0.2s all;
  }
   .error .btn:hover {
    text-shadow: 0px 1px 1px #ffffff;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.1), inset 0px -3px 7px 0px rgba(0, 0, 0, 0.2);
    transition: ease-out 0.2s all;
  }
  
