.products{
  position:relative;
  text-align: left;
  background-color: aliceblue;
}

.banner_img{
  margin-top:80px;
  width:100%;
}
.banner_img2{
  display:none;
}
.prod_body{
  padding:0px 10%;
  text-align: justify;
}

.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:15px;
}
.prod_main_head{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:600;
  letter-spacing: 2px;
  font-size:40px;
  text-align: left;
}
.prod_main_head2{
  background: -webkit-linear-gradient(#2B32B2, #1488CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:Poppins;
  font-weight:500;
  letter-spacing: 2px;
  font-size:25px;
  text-align: left;
}

.prod_main_body{
  color:#202020;
  font-family:Karla;
  font-weight:300;
  letter-spacing: 1px;
  font-size:20px;
}

.prod_image{
  display:flex;
  align-items: center;
  background-size: auto;
}


.products ul li::before {
  content: "\2022";
  color: rgba(0, 47, 255, 0.562);
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.prod_button{
  margin-right:30px;
}

.prod_slide{
  z-index: 0;
  color: aliceblue;
  width:80%;
  margin-left:10%;
  text-align:center;

  --slider-height-percentage: 58%;
--slider-transition-duration: 900ms;
--organic-arrow-thickness: 4px;
--organic-arrow-border-radius: 0px;
--organic-arrow-height: 40px;
--organic-arrow-color: #4e4e4e;
--control-button-width: 15%;
--control-button-height: 25%;
--control-button-background: none;
--control-bullet-color: #aebeff;
--control-bullet-active-color: #4e6bdf;
--loader-bar-color: #851515;
--loader-bar-height: 5px;
}
.prod_slide_img{
  width:100%;
}



@media only screen and (min-width: 800px)and (max-width: 1100px) {
  .banner_img{
    margin-top:60px;
  }
  .banner_img2{
    display:none;
  }
  .prod_body{
    padding:0px 10%;
  }
  
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:12px;
}
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
  .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:20px;
  }
}

@media only screen and (min-width: 700px)and (max-width: 800px) {
  .banner_img{
    margin-top:60px;
  }
  .banner_img2{
    display:none;
  }
  .prod_body{
    padding:0px 10%;
  }
  
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:25px;
    }
      .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:16px;
    }
  .prod_main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:15px;
  }
  
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:12px;
}
}

@media only screen and (min-width: 700px) and (max-width: 800px)  and (orientation:portrait) {
  .banner_img{
    display:none;
  }
  .banner_img2{
    display:block;
    margin-top:60px;
    width:100%;
  }
  .prod_body{
    padding:0px 10%;
  }
  
  .prod_main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:20px;
  }
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
  .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:22px;
  }
  .prod_slide{
    z-index: 0;
    color: aliceblue;
    width:100%;
    margin-left:0%;
  }
  
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:30px;
  }
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:15px;
}
}

@media only screen and (min-width: 10px)and (max-width: 700px) {
  .banner_img{
    display:none;
  }
  .banner_img2{
    display:block;
    margin-top:0px;
    padding-top: 60px;
    width:100%;
  }
  .prod_main_body{
    color:#202020;
    font-family:Karla;
    font-weight:300;
    letter-spacing: 1px;
    font-size:17px;
  }
  .prod_main_head{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:600;
    letter-spacing: 2px;
    font-size:25px;
  }
  
  .prod_main_head2{
    background: -webkit-linear-gradient(#2B32B2, #1488CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    font-weight:500;
    letter-spacing: 2px;
    font-size:18px;
  }
  .prod_slide{
    z-index: 0;
    color: aliceblue;
    width:100%;
    margin-left:0%;
    text-align:center;
  
    --slider-height-percentage: 48%;
  --slider-transition-duration: 900ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #4e4e4e;
  --control-button-width: 15%;
  --control-button-height: 25%;
  --control-button-background: none;
  --control-bullet-color: #aebeff;
  --control-bullet-active-color: #4e6bdf;
  --loader-bar-color: #851515;
  --loader-bar-height: 5px;
  }
  
.main_body_sm1{
  font-family:Karla;
  font-weight:400;
  font-size:10px;
}
}